
$blue: hsla(218, 61%, 46%, 1);
$cyan: hsla(211, 26%, 57%, 1);
$dark-blue: hsla(213, 100%, 17%, 1);
$dark-gray: hsla(238, 16%, 34%, 1);
$dark-red: hsla(0, 83%, 60%, 1);
$gray: hsla(240, 14%, 90%, 1);
$green: hsla(124, 38%, 59%, 1);
$dark-green: hsla(112, 93%, 36%, 1);
$light-gray: hsla(240, 13%, 94%, 1);
$light-green: hsla(131, 67%, 95%, 1);
$light-red: hsla(0, 100%, 97%, 1);
$medium-dark-gray:hsla(240, 15%, 83%, 1);
$notification-orange: hsla(32, 88%, 45%, 1);
$notification-red: hsla(0, 100%, 50%, 1);
$notification-yellow: hsla(47, 97%, 55%, 1);
$red: hsla(0, 83%, 60%, 1);
$white: hsla(0, 0%, 100%, 1);
$whiteOpacity: hsla(0, 0%, 100%, 0.9);
$transparent: hsla(0, 0%, 0%, 0);

$map-control-postion-right: 24px;
