@use './variables.scss';

.AppContainer {
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  font-family: Montserrat;
  color: variables.$dark-blue;

  .App {
    text-align: center;
    padding-bottom: 126px; // Footer height

    main {
      width: 100%;
      padding-bottom: 60px;
    }
  }
}

.Form {
  padding-top: 60px;
}