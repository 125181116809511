@use '../../variables.scss';

.environment-banner {
  position: absolute;
  top: 4px;
  width: 100%;
  margin: auto;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 17px;
  color: red;
  align-items: flex-start;
  justify-content: center;
  display: flex;
}

.appbar-logo {
  display: flex;
  width: 120px;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: variables.$white;
}

.appbar-title {
  font-family: Montserrat;
  font-size: 3rem;
  display: none;

  @media (min-width: 600px) {
    display: block;
  }
}

.appbar-sign-in {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 1.2rem;
  color: variables.$white;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}