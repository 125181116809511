@use '../variables.scss';

.home-background-image {
  background-image: url(./home.jpg);
  background-size: cover;
  background-position: right 100px;
}

.home-page-box {
  background-color: hsla(0, 0%, 100%, 0.7);
  padding: 30px 40px;
}

.home-page-email {
  display: block;
  font-weight: 700;
  text-decoration: none;
  color: variables.$dark-gray;
}