@use '../variables.scss';

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: variables.$light-gray;
  color: variables.$dark-blue;
  text-align: center;
  min-height: 126px;
  padding-top: 26px;
  padding-bottom: 46px;
  text-align: left;

  .MuiContainer-root {
    display: flex;

    .footer-element {
      margin-left: 15px;
      margin-right: 15px;

      a {
        color: variables.$dark-blue;
      }
    }
  }
}